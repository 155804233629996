
import {
  IonContent, 
  IonPage, 
  IonSlides,
  IonSlide,
  IonButton,
  IonImg
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import router from '@/router';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    IonSlides,
    IonSlide,
    IonButton,
    IonImg
  },
  setup() {
    const slidesRef = ref();
    const options = {
      keyboard: true,
      // navigation: {
      //   prevEl: '#swiper-prev',
      //   nextEl: '#swiper-next',
      // },
    }

    const slideToPrev = async () => {
      if (!slidesRef.value) return;
      await slidesRef.value.$el.slidePrev();
    }
    const slideToNext = async () => {
      if (!slidesRef.value) return;
      await slidesRef.value.$el.slideNext();
    }
    
    return {
      slideToPrev,
      slideToNext,
      slidesRef,
      options,
    }
  },
  methods: {
    onboardingCompleted() {
      this.$eatableStorage.set('onboarding', 'completed');
      const getPreviousUrl = router.options.history.state.back;
      if ( getPreviousUrl && typeof getPreviousUrl === 'string' ) {
        router.push(getPreviousUrl);
      } else {
        router.push('/');
      }
    }
  }
});
